.featured-carousel-item {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: var(--color-bg-primary-light);
  transition: transform 300ms ease-out;
  padding: 12px;
}

.featured-carousel-item:hover {
  transform: scale(1.01);
  transition: transform 80ms ease-in;
}

.carousel-item-banner {
  width: 100%;
  aspect-ratio: 3 / 1.8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.carousel-item-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

.carousel-item-header-container {
  position: relative;
}

.carousel-item-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.carousel-item-image img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid var(--color-bg-primary);
}

.carousel-text-content {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  width: 100%;
}

.carousel-text-content-header {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.carousel-text-header-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 20px;
  flex-grow: 1;
}

.carousel-text-header-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 26px;
}

.carousel-text-header-tertiary-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 12px;
}

.carousel-text-header-ticker {
  display: flex;
  padding: 4px 8px;
  background-color: var(--color-bg-secondary);
  border-radius: 99px;
  border: none;
}

.carousel-text-header-secondary-text {
  color: var(--color-text-tertiary-faded-70);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 16px;
  margin-top: 6px;
  width: 90%;
}

.carousel-text-content-footer {
  display: flex;
  width: 100%;
  margin-top: 12px;
  flex-direction: column;
  gap: 12px;
}

.carousel-text-footer-container {
  display: flex;
  width: 100%;
  gap: 4px;
  flex-direction: column;
}

.carousel-text-footer-title-text {
  color: var(--color-text-tertiary-faded-50);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 14px;
}

.carousel-text-footer-body-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 20px;
}

.carousel-text-footer-contributions {
  display: flex;
  width: 100%;
  gap: 4px;
  flex-direction: row;
}

.progress-container {
  width: 100%;
  height: 10px;
  background-color: var(--color-hover-secondary);
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  height: 11px;
  background-color: var(--color-bg-secondary);
  transition: width 0.3s ease-in-out;
}
