.section-label {
  display: inline-flex;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 999px;
  background-color: var(--color-hover-primary);
  border: 1px solid var(--color-hover-primary);
}

.section-label-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 20px;
  white-space: nowrap;
}

.section-icon-component {
  display: flex;
  align-items: center;
  justify-content: center;
}
