@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  background-color: var(--color-bg-primary);
  transition: all 0.8s ease;
}

html.dark-theme {
  --color-bg-primary: #1b1b1b;
  --color-bg-primary-light: #181818;
  --color-bg-secondary: #f4f6f8;
  --color-bg-secondary-light: #f2f2f4;
  --color-text-primary: #f4f6f8;
  --color-text-secondary: #1b1b1b;
  --color-text-secondary-faded-92: rgba(27, 27, 27, 0.92);
  --color-text-secondary-faded-50: rgba(27, 27, 27, 0.5);
  --color-text-secondary-faded-30: rgba(27, 27, 27, 0.3);
  --color-text-tertiary-faded-70: rgba(244, 246, 248, 0.7);
  --color-text-tertiary-faded-50: rgba(244, 246, 248, 0.5);
  --color-hover-primary: rgba(244, 246, 248, 0.05);
  --color-hover-secondary: rgba(244, 246, 248, 0.1);
  --color-hover-tertiary: rgba(27, 27, 27, 0.1);
  --color-border-light: rgba(27, 39, 61, 0.1);
  --color-accent-one: #4e2bcd;
  --light: #ffffff;
  --dark: #000000;
}

html.light-theme {
  --color-bg-primary: #ffffff;
  --color-bg-primary-light: #f0f0f0;
  --color-bg-secondary: #1b1b1b;
  --color-bg-secondary-light: #2d2d2d;
  --color-text-primary: #1b1b1b;
  --color-text-secondary: #f4f6f8;
  --color-text-secondary-faded-92: rgba(244, 246, 248, 0.92);
  --color-text-secondary-faded-50: rgba(244, 246, 248, 0.5);
  --color-text-secondary-faded-30: rgba(244, 246, 248, 0.3);
  --color-text-tertiary-faded-70: rgba(27, 27, 27, 0.7);
  --color-text-tertiary-faded-50: rgba(27, 27, 27, 0.5);
  --color-hover-primary: rgba(27, 27, 27, 0.05);
  --color-hover-secondary: rgba(27, 27, 27, 0.1);
  --color-hover-tertiary: rgba(244, 246, 248, 0.1);
  --color-border-light: rgba(244, 246, 248, 0.2);
  --color-accent-one: #7d1eff;
  --light: #000000;
  --dark: #ffffff;
}

@font-face {
  font-family: "PriegoBold";
  src: url("assets/fonts/Fontspring-DEMO-priego-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

button {
  border: none;
}
