.landing-page-navigation {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 30px;
  z-index: 1;
}

@media (max-width: 1080px) {
  .landing-page-navigation {
    padding: 12px 20px;
  }
}

.landing-page-nav-logo-text {
  color: var(--color-text-primary);
  font-family: "PriegoBold", sans-serif;
  font-size: 24px;
  letter-spacing: -0.2px;
  line-height: 26px;
}

.landing-page-navigation-logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 1080px) {
  .landing-page-navigation-logo-container {
    flex-grow: 1;
  }
}

.landing-page-navigation-sections {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 12px;
  justify-content: center;
  width: 100%;
}

.landing-page-nav-signup-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 20px;
  white-space: nowrap;
  text-decoration-line: none;
}

.landing-page-navigation-signup-button-container-link {
  text-decoration: none;
}

.landing-page-nav-signup-text-shortened {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 18px;
  white-space: nowrap;
}

.landing-page-navigation-signup-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 12px 16px;
  cursor: pointer;
  background-color: var(--color-bg-secondary);
  border-radius: 8px;
  transition: transform 300ms ease;
  border: none;
}

.landing-page-navigation-signup-button-container:hover {
  transform: scale(1.05);
}

@media (max-width: 1080px) {
  .landing-page-navigation-signup-button-container {
    padding: 10px 12px;
  }
  .landing-page-navigation-signup-button-container:hover {
    transform: none;
  }
}

@media (max-width: 1080px) {
  .landing-page-navigation-sections,
  .landing-page-nav-logo-text,
  .landing-page-nav-signup-text {
    display: none;
  }
}

button.landing-page-drop-down-menu {
  display: flex;
  padding: 12px 18px;
  background-color: var(--color-hover-secondary);
  border: 1px solid var(--color-hover-secondary) !important;
  border-radius: 8px;
  border: none;
  margin-left: 8px;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

@media (min-width: 1081px) {
  .landing-page-nav-signup-text-shortened,
  button.landing-page-drop-down-menu {
    display: none;
  }
}

.navigation-dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms, background-color 300ms;
  pointer-events: none;
  z-index: 1;

  &.visible {
    opacity: 1;
    background-color: var(--color-text-secondary-faded-92);
    pointer-events: all;
  }
}

.navigation-dialog-content {
  padding: 8px;
  background-color: var(--color-hover-secondary);
  border: 1px solid var(--color-hover-secondary) !important;
  border-radius: 12px;
  box-shadow: 0 4px 6px var(--color-hover-tertiary);
  flex-direction: row;
  display: flex;
  position: absolute;
  top: 60px;
  right: 20px;
  flex-direction: column;
}

.landing-page-theme-button {
  display: flex;
  height: 44px;
  min-width: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 12px;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-hover-secondary);
  background-color: var(--color-hover-primary);
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover-secondary);
  }
}

@media (max-width: 1080px) {
  .landing-page-theme-button {
    height: 38px;
    min-width: 38px;
    margin-right: 8px;
  }
}
