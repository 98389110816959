.footer-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--color-bg-secondary);
  border-radius: 24px;
  margin-bottom: 24px;
  z-index: 2;
  padding: 36px;
}

@media (max-width: 1080px) {
  .footer-container {
    padding: 16px;
    margin-bottom: 0px;
    border-radius: 24px 24px 0px 0px;
  }
}

.footer-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 24px 30px;
  z-index: 2;
}

@media (max-width: 1080px) {
  .footer-wrapper {
    padding: 0px;
  }
}

.input-container {
  display: flex;
  width: 65%;
  height: 100%;
  background-color: var(--light);
  flex-direction: column;
  border-radius: 24px;
}

@media (max-width: 1080px) {
  .input-container {
    width: 100%;
  }
}

.footer-header-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

@media (max-width: 1080px) {
  .footer-header-wrapper {
    flex-direction: column;
    gap: 24px;
  }
}

.footer-header-logo-wrapper {
  display: flex;
  flex-grow: 1;
}

.footer-primary-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 24px;
  flex-grow: 1;
}

.sign-up-input {
  width: 100%;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: 72px;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 72px;
  color: var(--color-text-secondary);
  border: none;
  outline: none;
  padding: 24px;
  caret-color: var(
    --color-text-secondary
  ); /* Changes the color of the cursor */
}

.sign-up-input::placeholder {
  color: var(--color-text-secondary-faded-50);
  font-weight: 500;
}

@media (max-width: 1080px) {
  .sign-up-input {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 16px;
  }
}

.footer-container-text-button {
  display: flex;
  width: 100%;
  padding: 0px 24px 24px 24px;
  gap: 12px;
  align-items: flex-end;
}

@media (max-width: 1080px) {
  .footer-container-text-button {
    padding: 0px 16px 16px 16px;
  }
}

.footer-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 12px 16px;
  cursor: pointer;
  background-color: var(--color-bg-primary);
  border-radius: 8px;
  transition: transform 300ms ease;
  z-index: 10;
  border: none;

  &:hover {
    transform: scale(1.05);
  }
}

.footer-button-signup-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 20px;
  white-space: nowrap;
}

.footer-body-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
}

@media (max-width: 1080px) {
  .footer-body-wrapper {
    flex-direction: column-reverse;
    gap: 24px;
    align-items: flex-start;
  }
}

.footer-body-waivlength-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.footer-secondary-text {
  color: var(--color-text-secondary-faded-50);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 24px;
  flex-grow: 1;
}

.footer-secondary-text-disclaimer {
  color: var(--color-text-secondary-faded-30);
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 00;
  letter-spacing: -1px;
  line-height: 12px;
}

.footer-body-grid-container {
  display: flex;
  width: 65%;
  height: 100%;
  flex-direction: column;
}

@media (max-width: 1080px) {
  .footer-body-grid-container {
    width: 100%;
  }
}

.grid-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding-top: 24px;
  gap: 6px;
}

@media (max-width: 1080px) {
  .grid-container {
    flex-direction: column;
  }
}

.grid-container-column {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.column-header {
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item {
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer-body-header-text {
  color: var(--color-text-secondary-faded-50);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 20px;
  flex-grow: 1;
}

.footer-body-body-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 20px;
  flex-grow: 1;
  text-decoration-line: none;
}
