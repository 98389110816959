.blog-header-component {
  display: flex;
  height: 100%;
  max-width: 768px;
  width: 100%;
  margin-top: 120px;
  flex-direction: column;
  padding: 24px;
}

@media (max-width: 768px) {
  .blog-header-component {
    margin-top: 72px;
    width: 100%;
    padding: 16px;
  }
}

.blog-header-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 92px;
  font-weight: 700;
  letter-spacing: -6px;
  line-height: 80px;
}

@media (max-width: 768px) {
  .blog-header-primary-text {
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -4px;
    line-height: 60px;
  }
}

.blog-header-tag {
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-hover-secondary);
  background-color: var(--color-hover-primary);
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .blog-header-tag {
    padding: 6px 12px;
    margin-bottom: 12px;
  }
}

.blog-header-tag-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 18px;
}

@media (max-width: 768px) {
  .blog-header-tag-text {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 16px;
  }
}

.blog-header-secondary-text {
  color: var(--color-text-tertiary-faded-70);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 26px;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .blog-header-secondary-text {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 24px;
    margin-top: 6px;
  }
}
