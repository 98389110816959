.section-info-container {
  display: inline-block;
  flex-direction: column;
  justify-content: flex-start;
}

.header-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -2.6px;
  line-height: 48px;
  max-width: 500px;
}

.body-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 28px;
  max-width: 600px;
}

.section-info-text-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
  padding: 16px 0px;
}
