.app-landing-page-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: fixed;
  top: 0;
  margin-top: 200px;
}

@media (min-height: 800px) {
  .app-landing-page-container {
    margin-top: 300px;
  }
}

.app-landing-page-container-svg {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

.app-landing-page-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 768px) {
  .app-landing-page-hero-section {
    flex-direction: column;
    gap: 6px;
  }
}

.app-landing-page-hero-first {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  gap: 24px;
}

.app-landing-page-logo-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 24px;
}

@media (max-width: 768px) {
  .app-landing-page-hero-first {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .app-landing-page-logo-text-container {
    gap: 12px;
  }
}

.app-landing-hero-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 92px;
  font-weight: 700;
  letter-spacing: -6px;
  line-height: 80px;
}

@media (max-width: 768px) {
  .app-landing-hero-primary-text {
    font-size: 54px;
    font-weight: 700;
    letter-spacing: -4px;
    line-height: 50px;
  }
}

.app-landing-page-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
}

.app-landing-hero-primary-subtext {
  color: var(--color-text-tertiary-faded-70);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 24px;
  text-align: center;
}

@media (min-height: 800px) {
  .app-landing-hero-primary-subtext {
    width: 90%;
  }
}

.app-landing-page-article-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;
  cursor: pointer;
  position: relative;
  border-radius: 999px;
  border: 1px solid var(--color-hover-primary);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    background-color: var(--color-hover-primary);
  }
}

@media (max-width: 1080px) {
  .app-landing-page-article-button {
    display: none;
  }
}

.app-landing-article-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 18px;
}

.app-landing-coming-soon-primary-subtext {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 14px;
}

.app-landing-page-app-stores-containter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.app-landing-page-app-stores-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.app-landing-article-secondary-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 14px;
}

.app-landing-page-article-button-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  position: relative;
  border-radius: 999px;
  background-color: var(--color-bg-secondary);
}
