.dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms, background-color 300ms;
  pointer-events: none;
  z-index: 5;

  &.visible {
    opacity: 1;
    background-color: var(--color-text-secondary-faded-92);
    pointer-events: all;
  }

  .dialog-content {
    padding: 24px;
    background: var(--color-bg-secondary);
    border-radius: 24px;
    box-shadow: 0 4px 6px var(--color-hover-tertiary);
    width: 90%;
    max-width: 1280px;
    flex-direction: row;
    display: flex;
    gap: 72px;
    height: 90%;
    overflow: hidden;
    position: relative;
  }

  @media (max-width: 768px) {
    .dialog-content {
      padding: 12px;
      border-radius: 16px;
      flex-direction: column;
      height: 84%;
      margin-top: 20px;
    }
  }

  @media (max-width: 1080px) {
    .dialog-content {
      gap: 24px;
    }
  }
}

@media (max-width: 768px) {
  .dialog-background {
    align-items: flex-start;
  }
}

.dialog-sign-up-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dialog-content-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 12px;
  height: 100%;
}

@media (max-width: 1080px) {
  .dialog-content-container {
    width: 100%;
  }
}

.dialog-sign-up-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 999px;
  border: 1px solid var(--color-border-light);
  transition: transform 0.3s ease, background-color 0.3s ease !important;
}

.dialog-sign-up-button-primary-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 18px;
}

.dialog-sign-up-header-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -3.5px;
  line-height: 52px;
  width: 80%;
}

@media (max-width: 768px) {
  .dialog-sign-up-header-text {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 40px;
  }
}

.dialog-sign-up-body-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1.5px;
  line-height: 26px;
}

@media (max-width: 768px) {
  .dialog-sign-up-body-text {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 20px;
  }
}

.dialog-sign-up-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .dialog-sign-up-input-container {
    height: 100%;
    margin-top: 12px;
  }
}

.dialog-sign-up-footer-text {
  color: var(--color-text-secondary-faded-50);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 14px;
}

@media (max-width: 768px) {
  .dialog-sign-up-footer-text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 16px;
  }
}

.dialog-sign-up-input {
  width: 100%;
  padding: 8px 10px;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  color: var(--color-text-secondary);
  border: none;
  outline: none;
  padding: 8px 16px;
  caret-color: var(--color-text-secondary);
  border: 1px solid var(--color-border-light);
  border-radius: 12px;
}

.dialog-sign-up-input::placeholder {
  color: var(--color-text-secondary-faded-50);
  font-weight: 400;
}

.dialog-sign-up-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;
  cursor: pointer;
  border-color: none;
  border-radius: 12px;
  background-color: var(--color-bg-primary);
  transition: transform 0.3s ease, background-color 0.3s ease !important;
}

.dialog-sign-up-button-text {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  color: var(--color-text-primary);
}

.dialog-sign-up-terms-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -1.1px;
  line-height: 16px;
}

.terms-link {
  font-weight: 700;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.terms-link:hover {
  color: var(--color-text-secondary-faded-50);
  transition: color 0.2s ease-in-out !important;
}

.dialog-sign-up-socials-container {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;
  padding: 16px;
  border-radius: 24px;
  border: 1px solid var(--color-border-light);
  align-items: flex-start;
}

.dialog-sign-up-header-socials-header-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 26px;
}

.dialog-sign-up-header-socials-text {
  color: var(--color-text-secondary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 18px;
}

.dialog-sign-up-social {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 10px 12px;
  border-radius: 12px;
  border: 1px solid var(--color-border-light);
  transition: transform 0.3s ease, border 0.3s ease !important;
  cursor: pointer;
}

.dialog-sign-up-close {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 10px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-border-light);
  transition: transform 0.3s ease, border 0.3s ease !important;
  cursor: pointer;
}

@media (min-width: 769px) {
  .dialog-sign-up-close {
    display: none;
  }
}

.dialog-sign-up-social:hover {
  border: 1px solid var(--color-text-secondary-faded-30);
  transition: color 0.2s ease-in-out !important;
}

.dialog-sign-up-social-image img {
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.dialog-mobile-preview-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  height: 100%;
}

@media (max-width: 768px) {
  .dialog-mobile-preview-container {
    display: none;
  }
}

.dialog-sign-up-app {
  width: 312px;
  aspect-ratio: 27 / 50;
}

.dialog-sign-up-app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 24px;
}

.dialog-sign-up-close-right {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 10px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-border-light);
  transition: transform 0.3s ease, border 0.3s ease !important;
  cursor: pointer;
}

@media (max-width: 769px) {
  .dialog-sign-up-close-right {
    display: none;
  }
}
