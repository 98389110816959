.app-wrapper {
  display: flex;
  height: 100%;
  width: 100vw;
  max-width: 1440px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.app-background-header-gradient {
  display: flex;
  justify-content: center;
  top: -1100px;
  position: fixed;
}

.app {
  display: flex;
  height: 100%;
  width: 100vw;
  max-width: 1440px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

