.featured-spaces-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
}

.featured-spaces-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 140px;
  font-weight: 700;
  letter-spacing: -8px;
  line-height: 160px;
}

@media (max-width: 767px) {
  .featured-spaces-primary-text {
    font-family: "Inter", sans-serif;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -6px;
    line-height: 80px;
  }
}

.featured-spaces-text-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: translate(-100px, -60px);
}

@media (max-width: 767px) {
  .featured-spaces-text-container {
    transform: translate(0px, -30px);
  }
}

.featured-spaces-text1-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: translate(100px, 60px);
}

.featured-spaces-text-overlay-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transform: translate(-100px, -60px);
}

@media (max-width: 767px) {
  .featured-spaces-text-overlay-container {
    transform: translate(0px, -30px);
  }
}

@media (max-width: 767px) {
  .featured-spaces-text1-container {
    transform: translate(0px, 40px);
  }
}

.featured-spaces-text1-overlay-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transform: translate(100px, 60px);
}

@media (max-width: 767px) {
  .featured-spaces-text1-overlay-container {
    transform: translate(0px, 40px);
  }
}

.featured-spaces-primary-text-overlay {
  -webkit-text-stroke: 2px var(--color-text-primary);
  color: transparent;
  font-family: "Inter", sans-serif;
  font-size: 140px;
  font-weight: 700;
  letter-spacing: -8px;
  line-height: 160px;
}

@media (max-width: 767px) {
  .featured-spaces-primary-text-overlay {
    font-family: "Inter", sans-serif;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -6px;
    line-height: 80px;
  }
}

.featured-spaces-background-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -400px;
}
