.navigation-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  border-radius: 999px;
  transition: transform 0.3s ease, background-color 0.3s ease, padding 0.3s ease;

  &:hover {
    background-color: var(--color-hover-primary);
    transform: scale(1);
    padding: 8px 40px 8px 16px;
  }

  &:hover .icon-component {
    opacity: 1;
    transform: scale(1) translateX(-2px);
  }
}

.navigation-button-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 20px;
  transition: transform 0.3s ease;
  position: relative;
  white-space: nowrap;

  .navigation-button-wrapper:hover & {
    transform: translateX(20px);
  }
}

.icon-component {
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 16px;
}
