.app-display-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.app-display-screen {
  display: flex;
  width: 270px;
  height: 500px;
  align-items: center;
  margin-top: 800px;
  z-index: 2;
}

.app-display-screen video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 33px;
  border: 1px solid var(--color-hover-primary);
  transform: translate3d(0, 0, 0);
  scale: (1.2);
}

@media (max-width: 768px) {
  .app-display-screen {
    margin-top: 860px;
    scale: (1);
  }
}
