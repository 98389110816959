.banner-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 92px;
  font-weight: 700;
  letter-spacing: -6px;
  line-height: 80px;
}

.banner-secondary-text {
  color: var(--color-text-primary);
  font-family: "PriegoBold", sans-serif;
  font-size: 92px;
  letter-spacing: -3px;
  line-height: 80px;
}

.app-store-banner {
  display: flex;
  margin-top: 24px;
}

.banner-hero-text-line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  gap: 24px;
}

@media (max-width: 1080px) {
  .banner-hero-text-line {
    gap: 18px;
    width: 100%;
  }
}

.banner-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 1080px) {
  .banner-hero-section {
    flex-direction: column;
    gap: 6px;
    width: 90%;
  }
}
