.banner-section {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.banner-section-content {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  max-width: 1440px;
  top: 0;
}
