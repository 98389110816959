.communities-section-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 24px 30px;
  z-index: 2;
}

.communities-table {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--color-bg-primary-light);
  border-radius: 24px;
  height: 100%;
  margin-top: 24px;
  height: 600px;
}

.communities-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px 24px 24px;
}

.communities-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -2.6px;
  line-height: 48px;
}
