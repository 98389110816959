.featured-sales-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.featured-sales-buttons-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px 24px 12px;
}

.featured-sales-button {
  display: flex;
  background-color: var(--color-hover-primary);
  border: 1px solid var(--color-hover-primary);
  border-radius: 999px;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms ease-in-out, opacity 300ms ease-in-out;

  &:disabled {
    cursor: default;
    background-color: transparent;
  }
}

.featured-sales-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .featured-carousel-container {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    touch-action: pan-y;
  }

  @media (min-width: 1024px) {
    .featured-carousel-item {
      width: calc(100% / 3 - 42px);
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .featured-carousel-item {
      width: calc(100% / 2 - 20px);
    }
  }

  @media (max-width: 767px) {
    .featured-carousel-item {
      width: calc(100% - 0px);
    }
  }

  @media (min-width: 1024px) {
    .featured-carousel-container {
      gap: 54px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .featured-carousel-container {
      gap: 32px;
    }
  }

  @media (max-width: 767px) {
    .featured-carousel-container {
      gap: 12px;
    }
  }
}
