.features-carousel-item-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 24px;
  transition: transform 300ms ease-out;
  box-shadow: 0px 0px 20px var(--color-hover-secondary);
}

.features-carousel-item {
  display: flex;
  width: 100%;
  height: 100%;
}

.features-carousel-item-wrapper:hover {
  transform: scale(1.01);
  transition: transform 80ms ease-in;
}

.features-carousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 24px;
}

.features-carousel-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  transform: translate3d(0, 0, 0);
}
