.blog-para-component {
  display: flex;
  height: 100%;
  max-width: 768px;
  width: 100%;
  flex-direction: column;
  padding: 24px;
}

@media (max-width: 768px) {
  .blog-para-component {
    margin-top: 12px;
    width: 100%;
    padding: 16px;
  }
}

.blog-para-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 36px;
  margin-bottom: 12px;
}


.blog-para-secondary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.6px;
  line-height: 24px;
  margin-bottom: 12px;
}

