.legal-wrapper {
  display: flex;
  height: 100%;
  width: 100vw;
  max-width: 1440px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
