.app-landing-page-app-stores-container {
  display: flex;
  gap: 6px;
}

.app-landing-page-app-stores-wrapper {
  position: relative;
  text-align: center;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-text-secondary-faded-50);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.dialog {
  background-color: var(--color-bg-secondary);
  padding: 36px;
  border-radius: 36px;
  text-align: center;
  position: relative;
}

.qr-code-container {
  display: flex;
  justify-content: center;
}
