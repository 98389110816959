.navigation-button-wrapper-drop-down {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  position: relative;
  border-radius: 999px;
  transition: transform 0.3s ease, background-color 0.3s ease, padding 0.3s ease;
}

.navigation-button-text-drop-down {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 20px;
  transition: transform 0.3s ease;
  position: relative;
  white-space: nowrap;
}

.icon-component-drop-down {
  display: flex;
  align-items: center;
  justify-content: center;
}
