.featured-sales-section-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 24px 30px;
  z-index: 2;
}

.featured-sales-primary-text {
  color: var(--color-text-primary);
  font-family: "Inter", sans-serif;
  font-size: 92px;
  font-weight: 700;
  letter-spacing: -6px;
  line-height: 120px;
  text-align: center;
}

@media (max-width: 768px) {
  .featured-sales-primary-text {
    font-size: 54px;
    font-weight: 700;
    letter-spacing: -4px;
    line-height: 50px;
    margin-bottom: 12px;
  }
}
